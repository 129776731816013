import React from 'react'
import styled from '@emotion/styled'
import { H1 } from '../components/shared/type'
import Container from '../components/shared/container'
import { mediaQueries } from '../theme'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'
import FooterCta from '../components/footer-cta'
import Faq from '../components/faq'

const Title = styled(H1)`
  margin: ${p => `0 0 ${p.theme.space[10]}`};
  text-transform: uppercase;

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[21]}`};
  }
`

const FaqPage = ({ location }) => (
  <Layout location={location}>
    <PageMetadata
      title="Frequently asked questions about Getform"
      description="Got a question about Getform? On this page you’ll find quick answers to the most common questions about using our forms. General inquiries, GDPR, and handling billing and payment - we've gathered all the key information you'll need to get started."
      keywords="faq getform, start with getform, gdpr in getform, getform pricing, create form, response limit getform, plans in getform"
    />

    <Container>
      <Title>Faq</Title>
      <Faq />
    </Container>

    <FooterCta />
  </Layout>
)

export default FaqPage
