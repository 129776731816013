import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { H2 } from '../../components/shared/type'
import BaseGeneralFAQ from './general-faq'
import BaseGdprFAQ from './gdpr-faq'
import BasePricingFAQ from './pricing-faq'

const Section = styled.section``

const GeneralFAQ = styled(BaseGeneralFAQ)`
  margin-bottom: ${p => p.theme.space[12]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[26]};
  }
`

const GdprFAQ = styled(BaseGdprFAQ)`
  margin-bottom: ${p => p.theme.space[12]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[26]};
  }
`

const PricingFAQ = styled(BasePricingFAQ)`
  margin-bottom: ${p => p.theme.space[12]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[26]};
  }
`

const Title = styled(H2)`
  margin: ${p => `0 0 ${p.theme.space[8]}`};
  font-size: ${p => p.theme.fontSizes[8]};

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[15]}`};
  }
`

const FAQ = ({ className }) => (
  <Section className={className}>
    <Title>General</Title>
    <GeneralFAQ />
    <Title>GDPR & Data processing</Title>
    <GdprFAQ />
    <Title>Plans & Pricing</Title>
    <PricingFAQ />
  </Section>
)

export default FAQ
