import React from 'react'
import { Card, CardSet } from '../shared/cards'

const GeneralFAQ = ({ className }) => (
  <CardSet storageKey="general-faq-carousel" className={className}>
    <Card title="How many forms can I create?">
      Our plans limit the number of responses, but in terms of the number of
      forms you can create, there's no limit.
    </Card>
    <Card title="Can I use a form on my website?">
      Sure, you can load your form as a popup when visitors click a button on
      your website or blog.
    </Card>
    <Card title="Can I integrate it with my email marketing tool?">
      It’s possible to integrate Getform with your email marketing service and
      send email addresses to your mailing list automatically. We also offer
      Webhooks and API to send collected data to a third-party app.
    </Card>
  </CardSet>
)

export default GeneralFAQ
