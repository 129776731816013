import React from 'react'
import { Card, CardSet } from '../shared/cards'

const GdprFAQ = ({ className }) => (
  <CardSet storageKey="gdpr-faq-carousel" className={className}>
    <Card title="What data do the forms collect from respondents?">
      In addition to the form fields you configure, all Getform widgets collect
      a visitor's IP address, location, OS, browser and device type. Please note
      that you are not allowed to collect sensitive personal data using Getform.
    </Card>
    <Card title="Can I sign a DPA with you?">
      We have eliminated the need to sign a Data Processing Agreement by
      including the Standard Contractual Clauses (SCCs) to the Terms of Service
      you agree to when you register a Getform account. SCCs have been validated
      by the Court of Justice of the EU as they offer sufficient safeguards to
      protect user data when it's transferred internationally.
    </Card>
    <Card title="Can I collect 'explicit consent' to process personal data using your forms?">
      Yes, you can collect explicit consent from your respondents when you
      capture their data. To do that, simply add a mandatory consent checkbox to
      your form. This way, your respondents won't be able to submit any
      information without providing explicit consent to data processing.
    </Card>
    <Card title="How long do you store collected data for?">
      By default, all the data in your account is stored for as long as the
      account exists. After you delete your account, the data is stored for 30
      days and then permanently deleted. If you need some of your account data
      deleted faster, let us know.
    </Card>
    <Card title="Where do you store data?">
      All data is stored on secure Amazon servers. The servers are located in
      the US.
    </Card>
    <Card title="What about the right to be forgotten, the right to object, etc.?">
      If you receive a request to erase or rectify personal data processed by
      Getform, simply contact us at contact@getform.com. We have the tools to
      accommodate such requests.
    </Card>
  </CardSet>
)

export default GdprFAQ
